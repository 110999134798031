@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');

.navbar {
  background-color: #22783A;
  overflow: auto;
}

.navbar-brand {
  font-size: 1rem !important;
  color: #000000 !important;
  text-align: center;

}

.navbar a {
  float: left;
  padding: 12px;
  color: white;
  text-decoration: none;
  font-size: 17px;
  width: 25%;
  /* Four links of equal widths */
  text-align: center;
}

.menu_active {
  color: #000000;
  font-weight: bold;
}

.navbar a:hover {
  background-color: #04AA6D;
}

@media (max-width: 991px) {
  .navbar-brand {
    font-size: 1.5rem !important;
  }

  .menu_active {
    font-weight: bold;
    border-bottom: none;
  }
}