@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Muli", sans-serif;
}

.reg-img {
  display: block;
  margin: 0 auto;
  max-height: 50rem;
  max-width: 23rem;
  border-radius: 5%;
}


@media (max-width: 991px) {
  .navbar-brand {
    font-size: 1.5rem !important;
  }

  .menu_active {
    font-weight: bold;
    border-bottom: none;
  }
}