@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');
body { 
  background-color: #fffff0; 
}

.card{
  background-color: transparent;
  /* background-color: #ffff; */
  border: transparent;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Muli", sans-serif;
}

.card-img-top{
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
  max-height: 12rem;
  max-width: 12rem;
  /* width: 80%; */
  /* border-radius: 50%; */
  /* align-self: center; */
  display: block;
  margin: 0 auto;
}

.container {
  position: relative;
  width: 50%;
  margin-top: 5%;
}

.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.container:hover .card-img-top {
  opacity: 0.3;
}

.container:hover .middle {
  opacity: 1;
}

.text {
  background-color: #04AA6D;
  color: white;
  font-size: 8px;
  padding: 12px 24px;
}

.title {
  color: #2E85AA;
  font-weight: bold;
  text-align: center;
  text-decoration: underline;
}
.title2 {
  color: #0846A6;
  font-weight: bold;
  text-align: center;
}

.title3 {
  font-weight: bold;
  text-align: center;
}

.card-text{
  color: #12120a;
  text-align: center;
}

.data-text{
  color: #12120a;
  text-align: left;
}

/*MAIN LANDING SECTION */
#header {
  width: 100%;
  height: 80vh;
  margin-top: 70px;
}

#header h1 {
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 700;
  color: black;
}

#header .brand-name {
  color: #3498db;
}

#header h2 {
  color: #484848;
  font-size: 24px;
}

#header .btn-get-started {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 50px;
  transition: 0.5s;
  color: #3498db;
  border: 2px solid #3498db;
  text-decoration: none;
}

#header .btn-get-started:hover {
  background: #3498db;
  color: #fff;
}

.header-img {
  text-align: right;
}

#header .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);

  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);

  }

  100% {
    transform: translateY(-10px);
  }
}

button {
  display: flex;
  align-self: flex-end;
  font-size: 0.8rem;
  font-weight: 500;
  color: #318980;
  background-color: #d6e7e6;
  border: 1px solid #d6e7e6;
  border-radius: 0.2rem;
  padding: 0.4rem 1.5rem;
  margin-top: 0.5rem;
  cursor: pointer;
  transition: all 0.5s ease;
}

button:hover{
  color: #ffffff;
  background-color: #318980;
}